import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

export default function PageImpressum({ data }) {
	return (
		<div>
			<Header data={data} />
			<section dangerouslySetInnerHTML={{ __html: data.impressum }} />
			<Footer />
		</div>
	);
}

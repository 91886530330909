import React from 'react';

export default function Lage({ data }) {
	const { iframe, adressblock, text } = data;
	return (
		<section id="lage">
			<h2>Lage</h2>
			{text && (
				<div
					className="content"
					dangerouslySetInnerHTML={{ __html: text }}
				></div>
			)}
			<div className="lage-map">
				{<div dangerouslySetInnerHTML={{ __html: iframe }} />}
				<div
					className="lage-adresse"
					dangerouslySetInnerHTML={{ __html: adressblock }}
				></div>
			</div>
		</section>
	);
}

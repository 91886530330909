import React from 'react';

export default function Ausstattung({ data }) {
	const { headline, subline, fakten_links, fakten_rechts, bild } = data;
	return (
		<section id="ausstattung">
			<h2>
				{headline}
				<br />
				<span className="subline">{subline}</span>
			</h2>
			<img src={bild} alt="Ausstattung" style={{ marginBottom: '50px' }} />
			<div className="grid-50">
				<div dangerouslySetInnerHTML={{ __html: fakten_links }} />
				<div dangerouslySetInnerHTML={{ __html: fakten_rechts }} />
			</div>
		</section>
	);
}

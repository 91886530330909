import React from 'react';
import Share from './Share';

export default function Intro({ data }) {
	const { id, headline, subline, text, quicklinks, social_media } = data;
	return (
		<section id={id}>
			<h2>
				{headline}
				<br />
				<span className="subline">{subline}</span>
			</h2>
			<Share data={social_media} />
			<div className="content">
				<div dangerouslySetInnerHTML={{ __html: text }}></div>

				<nav>
					<h3 className="sub">Entdecken Sie</h3>
					<ul>
						{quicklinks &&
							quicklinks.map((link, idx) => {
								return (
									<li key={`link-${idx}`}>
										<a href={`#${link.hash}`}>{link.text}</a>
									</li>
								);
							})}
					</ul>
				</nav>
			</div>
		</section>
	);
}

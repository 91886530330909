import React, { useState } from 'react';
import Geschoss from './wohnungen/geschoss';
import Overlay from './wohnungen/overlay';

export default function Geschosse({ data }) {
	const [activeGeschoss, setActiveGeschoss] = useState(null);
	const [open, setOpen] = useState(false);
	const { geschosse, headline, subline, text } = data;

	function openGeschoss(key) {
		console.log(geschosse[key]);
		setActiveGeschoss(key);
		setOpen(true);
	}

	function hideOverlay() {
		setActiveGeschoss(null);
		setOpen(false);
	}

	function nextSlide() {
		if (activeGeschoss < geschosse.length - 1) {
			setActiveGeschoss(activeGeschoss + 1);
		} else {
			setActiveGeschoss(0);
		}
	}

	function prevSlide() {
		if (activeGeschoss === 0) {
			setActiveGeschoss(geschosse.length - 1);
		} else {
			setActiveGeschoss(activeGeschoss - 1);
		}
	}

	return (
		<section id="geschosse">
			<h2>
				{headline}
				<br /> <span className="subline">{subline}</span>
			</h2>
			{text && (
				<div
					className="content"
					dangerouslySetInnerHTML={{ __html: text }}
				></div>
			)}
			<div className="geschoss-grid">
				{geschosse &&
					geschosse.map((geschoss, idx) => {
						return (
							<Geschoss
								key={`geschoss-${idx}`}
								data={geschoss}
								openGeschoss={openGeschoss}
								index={idx}
							/>
						);
					})}
			</div>
			{open && (
				<Overlay
					nextSlide={nextSlide}
					prevSlide={prevSlide}
					hideOverlay={hideOverlay}
					data={geschosse[activeGeschoss]}
				/>
			)}
		</section>
	);
}

import React from 'react';
import Header from '../components/Header';
import Hero from '../components/Hero';
import SliderSmall from '../components/Slider_Small';
import Intro from '../components/Intro';
import Lage from '../components/Lage';
import Ausstattung from '../components/Ausstattung';
import Geschosse from '../components/Geschosse';
import Downloads from '../components/Downloads';
import Kontakt from '../components/Kontakt';
import Footer from '../components/Footer';
import Video from '../components/Video';

export default function PageIndex({ data }) {
	return (
		<div className="App">
			<Header data={data} />
			{data.inhalt &&
				data.inhalt.map((item, idx) => {
					switch (item.acf_fc_layout) {
						case 'slider':
							return <Hero key={`page-${idx}`} data={item} />;
						case 'slider_small':
							return <SliderSmall key={`page-${idx}`} data={item} />;
						case 'intro':
							return <Intro key={`page-${idx}`} data={item} />;
						case 'maps':
							return <Lage key={`page-${idx}`} data={item} />;
						case 'kontakt':
							return <Kontakt key={`page-${idx}`} data={item} />;
						case 'downloads':
							return <Downloads key={`page-${idx}`} data={item} />;
						case 'ausstattung':
							return <Ausstattung key={`page-${idx}`} data={item} />;
						case 'youtube':
							return <Video key={`page-${idx}`} data={item} />;
						case 'haus':
							return <Geschosse key={`page-${idx}`} data={item} />;
						default:
							return <div key={`page-${idx}`}></div>;
					}
				})}
			<Footer data={data.footer} />
		</div>
	);
}

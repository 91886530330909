import React, { useState } from 'react';
import axios from 'axios';

export default function Kontakt({ data }) {
	const { headline, subline, text, logo, ansprechpartner } = data;
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');
	const [isSent, setIsSent] = useState(false);

	function submitForm(e) {
		e.preventDefault();
		axios
			.post('https://submit-form.com/UabEVakZ', {
				name: name,
				email: email,
				message: message,
			})
			.then((response) => {
				setIsSent(true);
			});
	}
	return (
		<section id="kontakt">
			<h2>
				{headline}
				<br />
				<span className="subline">{subline}</span>
			</h2>
			<aside>
				<img
					style={{ marginTop: '40px', maxWidth: '80%' }}
					alt="Falk"
					src={logo}
				/>
			</aside>
			<div className="content" dangerouslySetInnerHTML={{ __html: text }}></div>
			<aside />
			<div className="content">
				<div className="fifty-fifty">
					{ansprechpartner &&
						ansprechpartner.map((person, idx) => {
							return (
								<div key={`kontakt-${idx}`}>
									<img alt="Profilbild" src={person.bild} />
									<strong>
										Ihr Ansprechpartner
										<br /> {person.thema}
									</strong>
									<br />
									{person.name}
									<br />
									{person.phone}
									<br /> Tel:{' '}
									<a href={`tel:${person.telefon}`}>{person.telefon}</a>
									<br />
									Mobil: <a href={`tel:${person.mobil}`}>{person.mobil}</a>
									<br />
									<a href={`mailto:${person.email}`}>{person.email}</a>
									<br />
								</div>
							);
						})}
				</div>
				{!isSent ? (
					<div>
						<h3>Kontaktformular</h3>
						<form onSubmit={(e) => submitForm(e)}>
							<label>
								Ihr Name
								<input
									onChange={(event) => setName(event.target.value)}
									type="text"
									name="name"
									value={name}
								/>
							</label>
							<input type="hidden" name="_redirect" value="false" />
							<label>
								Ihre E-Mail-Adresse
								<input
									onChange={(event) => setEmail(event.target.value)}
									type="text"
									name="email"
									value={email}
								/>
							</label>
							<label>
								Ihre Nachricht
								<textarea
									onChange={(event) => setMessage(event.target.value)}
									name="message"
									value={message}
								/>
							</label>
							<input type="submit" value="Abschicken" />
						</form>
					</div>
				) : (
					<div className="success">
						{' '}
						Ihre Nachricht wurde erfolgreicht versendet.
					</div>
				)}
				<div style={{ clear: 'both' }} />
			</div>
		</section>
	);
}

import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

export default function Overlay({ data, prevSlide, nextSlide, hideOverlay }) {
	return (
		<div className="overlay">
			<div className="inner">
				<div>
					<div>
						<div className="overlay-arrow left" onClick={() => prevSlide()} />
						<div className="overlay-arrow right" onClick={() => nextSlide()} />
						<div className="overlay-close" onClick={() => hideOverlay()} />
						<h2>
							{data.headline}
							<br />
						</h2>
						<span className="sub-wohnung">{data.subline}</span>
					</div>
					<div className="content">
						<div className="overlay-facts">Fakten & Zahlen</div>

						{data.zimmer &&
							data.zimmer.map((zimmer, idx) => {
								return (
									<div>
										{zimmer.label}{' '}
										<span className="number">
											ca. {zimmer.number.replace('.', ',')}m²
										</span>
									</div>
								);
							})}
					</div>
				</div>
				<div>
					<ImageGallery
						showNav={false}
						showPlayButton={false}
						items={data.slides.map((slide) => {
							return { original: slide, thumbnail: slide };
						})}
					/>
				</div>
			</div>
		</div>
	);
}

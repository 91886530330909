import React, { useEffect, useState } from 'react';
import './App.css';
import axios from 'axios';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import PageIndex from './pages/index';
import PageImpressum from './pages/impressum';
import PageDatenschutz from './pages/datenschutz';
import Loader from './components/Loader';

export default function App() {
	const [data, setData] = useState(null);
	useEffect(() => {
		axios
			.get('https://wupper-lofts.de/wp-json/htn/v1/suedterrassen')
			.then((response) => {
				setData(response.data);
			})
			.catch((error) => {
				alert('Fehler beim Laden');
			});
	}, []);
	if (!data) return <Loader />;
	return (
		<Router>
			<div className="App">
				<Route exact path="/" render={() => <PageIndex data={data} />} />
				<Route
					exact
					path="/datenschutz"
					component={() => <PageDatenschutz data={data} />}
				/>
				<Route
					exact
					path="/impressum"
					render={() => <PageImpressum data={data} />}
				/>
			</div>
		</Router>
	);
}

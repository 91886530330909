import React from 'react';

export default function Geschoss({ data, openGeschoss, index }) {
	const { headline, subline, slides } = data;
	return (
		<div className="wohnung">
			{slides.length && <img alt="vorschau" src={slides[0]} />}
			<div className="wohnung-inner">
				<h3>{headline}</h3>
				<div className="type">{subline}</div>
				<div className="arrow" onClick={() => openGeschoss(index)}>
					Details anzeigen
				</div>
			</div>
		</div>
	);
}

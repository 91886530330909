import React from 'react';
import Carousel from 'nuka-carousel';

export default function SliderSmall({ data }) {
	const { slides, id, headline, subline, text } = data;
	return (
		<div>
			<section id={id}>
				<h2>
					{headline && headline}
					{subline && (
						<React.Fragment>
							<br />
							<span className="subline">{subline}</span>
						</React.Fragment>
					)}
				</h2>
				<div
					className="content"
					dangerouslySetInnerHTML={{ __html: text }}
				></div>
				<Carousel
					heightMode="max"
					renderCenterLeftControls={({ previousSlide }) => (
						<div className="prev" onClick={previousSlide} />
					)}
					renderCenterRightControls={({ nextSlide }) => (
						<div className="next" onClick={nextSlide} />
					)}
					renderBottomRightControls={({ nextSlide, previousSlide }) => {
						return <div />;
					}}
				>
					{slides &&
						slides.map((slide, idx) => {
							return (
								<div key={idx} className="slide">
									<img
										style={{ maxHeight: '700px' }}
										alt={slide.text}
										src={slide.bild}
									/>
									<div className="slide-caption">{slide.text}</div>
								</div>
							);
						})}
				</Carousel>
			</section>
		</div>
	);
}

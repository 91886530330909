import React from 'react';
import { Link } from 'react-router-dom';

export default function Header({ data }) {
	return (
		<header>
			<Link to="/">
				<img src={data.logo} alt="Logo" rel="Logo" />
			</Link>
			<a href="tel:02122401777" className="tel">
				<span className="icon" />
				0212 / 2401777
			</a>
		</header>
	);
}

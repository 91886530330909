import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer({ data }) {
	return (
		<footer>
			<section id="footer">
				<div
					className="footer-partner"
					dangerouslySetInnerHTML={{ __html: data }}
				></div>
				<div className="footer-lower">
					© 2021 HTN Immobilien Management GmbH |{' '}
					<Link to="/impressum">Impressum</Link> |{' '}
					<Link to="/datenschutz">Datenschutz</Link>
				</div>
			</section>
		</footer>
	);
}

import React from 'react';
import YouTube from 'react-youtube';

export default function Video({ data }) {
	const { video_id } = data;
	const opts = {
		height: '500',
		width: '100%',
		playerVars: {
			// https://developers.google.com/youtube/player_parameters
			autoplay: 0,
		},
	};

	return (
		<section id="youtube">
			<YouTube videoId={video_id} opts={opts} />
		</section>
	);
}

import React from 'react';
import Carousel from 'nuka-carousel';

export default function Hero({ data }) {
	const { slides, logo, hashlink } = data;
	return (
		<section id={'hero'}>
			<div className="hero-inner">
				<img alt="Logo" className="svg-logo" src={logo} />
				<br />
				<a href={`#${hashlink}`} className="btn btn-white">
					Mehr erfahren
				</a>
			</div>{' '}
			<div className="hero-slidewrapper">
				<Carousel
					transitionMode={'fade'}
					autoplay={true}
					speed={2000}
					renderCenterLeftControls={({ previousSlide }) => ''}
					renderCenterRightControls={({ nextSlide }) => ''}
					renderBottomRightControls={({ nextSlide, previousSlide }) => {
						return (
							<div>
								<div className="prev" onClick={previousSlide} />
								<div className="next" onClick={nextSlide} />
							</div>
						);
					}}
				>
					{slides &&
						slides.map((image, idx) => {
							return (
								<div
									key={`slide-${idx}`}
									className="hero-slide"
									style={{ backgroundImage: 'url(' + image + ')' }}
								/>
							);
						})}
				</Carousel>
			</div>
			<div className="black-overlay" />
		</section>
	);
}

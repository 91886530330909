import React from 'react';

export default function Downloads({ data }) {
	const { files, headline, id } = data;
	return (
		<section id={id}>
			<h2>{headline}</h2>
			<div className="download-grid">
				{files &&
					files.map((file, idx) => {
						return (
							<a
								rel="noopener noreferrer"
								key={`download-${idx}`}
								href={file.datei}
								className="download"
								target="_blank"
							>
								<img alt="Download" src={file.thumbnail} />
								<span className="download-title">{file.headline}</span>
								<div className="download-icon" />
							</a>
						);
					})}
			</div>
		</section>
	);
}

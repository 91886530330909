import React, { Component } from 'react';
import shareLink from '../img/share.svg';
import shareLinkwhite from '../img/share-white.svg';
import fbLink from '../img/fb.svg';
import fbLinkwhite from '../img/fb-white.svg';
import twitterLink from '../img/twitter.svg';
import twitterLinkwhite from '../img/twitter-white.svg';
import linkedLink from '../img/linked.svg';
import linkedLinkwhite from '../img/linked-white.svg';
import emailLink from '../img/mail.svg';
import emailLinkwhite from '../img/mail-white.svg';

class Share extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	copyToClipboard = (e) => {
		e.preventDefault();
		this.textArea.select();
		document.execCommand('copy');
		e.target.focus();
		this.setState({ copySuccess: 'Copied!' });
		alert('Link kopiert.');
	};
	render() {
		return (
			<aside>
				<ul>
					<li>
						<a
							rel="noopener noreferrer"
							href="/#"
							onClick={this.copyToClipboard}
						>
							<img src={shareLink} alt="Link teilen" />
							<img src={shareLinkwhite} alt="Link teilen" />
							<input
								style={{ opacity: 0 }}
								ref={(textarea) => (this.textArea = textarea)}
								type="text"
								readOnly
								defaultValue="https://www.suedterrassen.de"
							/>
						</a>
					</li>
					<li>
						<a
							rel="noopener noreferrer"
							target="_blank"
							href={
								'https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Fwww.suedterrassen.de'
							}
						>
							<img src={fbLink} alt="Facebook teilen" />
							<img src={fbLinkwhite} alt="Facebook teilen" />
						</a>
					</li>
					<li>
						<a
							rel="noopener noreferrer"
							target="_blank"
							href={'https://twitter.com/home?status=www.suedterrassen.de'}
						>
							{' '}
							<img src={twitterLink} alt="Twitter teilen" />
							<img src={twitterLinkwhite} alt="Twitter teilen" />
						</a>
					</li>
					<li>
						<a
							rel="noopener noreferrer"
							target="_blank"
							href={
								'https://www.linkedin.com/shareArticle?mini=true&url=https%3A//www.suedterrassen.de&title=Südterrassen&summary=&source='
							}
						>
							<img src={linkedLink} alt="LinkedIn teilen" />
							<img src={linkedLinkwhite} alt="LinkedIn teilen" />
						</a>
					</li>
					<li>
						<a
							rel="noopener noreferrer"
							href="mailto:deine@email.de?subject=Sued%Terrassen	&body=www.suedterrassen.de"
						>
							<img src={emailLink} alt="Email teilen" />
							<img src={emailLinkwhite} alt="Email teilen" />
						</a>
					</li>
				</ul>
			</aside>
		);
	}
}

export default Share;
